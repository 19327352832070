<template>
  <div>
    <PageHeader fileName="header-12" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="pt-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph1") }}
            </h6>

            <p>
              {{ $lang("header.paragraph2") }}
            </p>
            <p>
              {{ $lang("header.paragraph3") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid py-7">
      <div class="row gap-y-4">
        <div
          v-for="(tab, ti) of $lang('tabs')"
          :key="ti"
          :class="tab.col_class"
        >
          <SectionCard :fileName="tab.image">
            <h1 v-if="tab.col_class === 'col-12'">{{ tab.title }}</h1>
            <h3 v-if="tab.col_class === 'col-lg-6'">{{ tab.title }}</h3>

            <ul class="link-list">
              <li v-for="(link, li) of tab.links" :key="li">
                <router-link class="h6" :to="link.to">
                  {{ link.title }}
                </router-link>
              </li>
            </ul>
          </SectionCard>
        </div>
      </div>
    </div>

    <section class="py-7 bg-secondary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0"> {{ $lang("footer.card1.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'our_courses' }"
            >
              <small class="m-0"> {{ $lang("footer.card2.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'faqs' }"
            >
              <small class="m-0"> {{ $lang("footer.card3.body") }} </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-11" theme="secondary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import SectionCard from "@/components/SectionCard.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    CardButton,
    PageHeader,
    ContactFormSection,
    SectionCard,
  },
  data: () => ({}),
};
</script>

<style></style>
